import styled from 'styled-components';
import tw from 'twin.macro';

export const Title = styled.h3`
  ${tw`font-semibold mb-4`};
`;

export const Image = styled.figure`
  ${tw`w-full rounded-lg overflow-hidden mt-4 mb-10`};
`;

export const Links = styled.div`
  ${tw`w-full flex flex-col justify-between md:flex-row`};
`;

export const Span = styled.span`
  ${tw`w-full my-2`};
  ${({ next }) => next && tw`w-full my-2 text-right`};
`;


export const DisqusContainer = styled.div`
  ${tw`max-w-screen-md w-full mx-auto p-8 sm:p-12`};
`;