import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { StickyShareButtons } from 'sharethis-reactjs';
import { DiscussionEmbed } from 'disqus-react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import Icon from 'components/ui/Icon';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const BlogPost = ({ data, pageContext }) => {
  const post = data.mdx;
  const { previous, next, slug } = pageContext;

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: {
      identifier: slug,
      title: post.frontmatter.title,
      language: 'id_ID'
    }
  };

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.description} />
      <Container>
        <TitleSection title={post.frontmatter.title} />
        <FormatHtml content={post.body} />
        <Styled.Links>
          <Styled.Span>
            {previous && (
              <Link to={previous.fields.slug} rel="previous">
                <span>
                  <Icon icon="arrow-left" />
                  &nbsp;&nbsp;&nbsp;
                </span>
                {previous.frontmatter.title}
              </Link>
            )}
          </Styled.Span>
          <Styled.Span next>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <Icon icon="arrow-right" />
                </span>
              </Link>
            )}
          </Styled.Span>
        </Styled.Links>
        <StickyShareButtons
          config={{
            alignment: 'left', // alignment of buttons (left, right)
            color: 'social', // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: 'counts', // button labels (cta, counts, null)
            language: 'en', // which language to use (see LANGUAGES)
            min_count: 0, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              'facebook',
              'twitter',
              'whatsapp'
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 0, // the corner radius on each button (INTEGER)
            show_total: true, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 48, // the size of each button (INTEGER)
            top: 160 // offset in pixels from the top of the page
          }}
        />
      </Container>
      <Styled.DisqusContainer>
        <DiscussionEmbed {...disqusConfig} />
      </Styled.DisqusContainer>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        description
      }
    }
  }
`;
