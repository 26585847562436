import styled from 'styled-components';
import tw from 'twin.macro';

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full m-auto py-4`};
`;

export const BoldTitle = styled.h1`
  ${tw`text-4xl lg:text-5xl font-bold leading-normal w-full text-center text-purple-600`};
  ${({ center }) => center && tw`text-center`};
`;


export const Title = styled.h1`
  ${tw`mb-4 text-4xl lg:text-5xl leading-normal font-bold w-full text-center mb-4`};
  ${({ center }) => center && tw`text-center`};
`;

export const SubTitle = styled.h4`
  ${tw`text-2xl text-gray-600 w-full text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const Separator = styled.h2`
  ${tw`relative w-2 h-8 mb-6 -mt-2`};
  ${({ center }) => center && tw`mx-auto`};

  &:before {
    content: '';
    ${tw`bg-indigo-900 h-full w-px absolute left-0`};
  }

  &:after {
    content: '';
    ${tw`bg-blue-500 h-6 w-px absolute ml-1`};
  }
`;
